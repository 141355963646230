<template>
  <v-layout>
    <v-navigation-drawer></v-navigation-drawer>
    <v-app-bar>
      <v-spacer />
      <v-btn @click="handleSnackbar()">Snackbar</v-btn>
      <v-btn @click="handleOpenConfirmDialog()">confirm dialog</v-btn>
      <v-btn @click="toggle()">toggle theme</v-btn>
      <v-btn v-if="isAuthenticated" @click="handleLogout">Logout</v-btn>
      <v-btn v-else to="/auth/login">Login</v-btn>
    </v-app-bar>

    <v-main>
      <NuxtPage />
    </v-main>
  </v-layout>
</template>

<script setup>
const { toggle } = useCustomTheme();
const { isAuthenticated, logout } = useSanctumAuth();
const confirm = useConfirmDialog();
const snackbar = useSnackbar();

const loading = ref(false);

// useUploader()
//   .upload("/api/upload", new FormData().append("file", null), (perc) => {
//     console.log(perc);
//   })
//   .then(() => {
//     console.log("success");
//   })
//   .catch(() => {
//     console.log("error");
//   });

function handleOpenConfirmDialog() {
  confirm.open({
    title: "Are you sure?",
    description: "This action cannot be undone.",
    loading,
    onConfirm: () => {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        confirm.close();
      }, 2000);
    },
  });
}

function handleLogout() {
  logout();
}

function handleSnackbar() {
  snackbar.warning(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    {
      timer: true,
      closable: true,
    }
  );
}
</script>

<style></style>
